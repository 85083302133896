/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React from 'react';
import { AppShellSwitch } from '@exo/frontend-common-app-shell';

const PageNotFound = () => <div>Page not found</div>;

const Routes = () => <AppShellSwitch missing={PageNotFound} />;

export default Routes;
