/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { apolloClientFactory } from '@exo/frontend-common-apollo';
import { ApplicationConfig } from '@exo/frontend-common-app-shell';
import { loadable } from '@exo/frontend-apps-base';
import { AppWrapper } from './client/AppWrapper';

/* @ts-ignore */
const featureFlag = s => s?.toUpperCase() === 'TRUE';

const client = apolloClientFactory({
  batch: process.env.GRAPHQL_BATCH === 'true',
  endpoint: process.env.GRAPHQL_ENDPOINT!    
});

const modules = {
  polarisLogin: loadable(
    () => import('@exo/frontend-features-insurance-polaris-login-ui/client/App')
  )
};

export default {
  bundles: [
    {
      id: 'ROOT',
      url: 'http://localhost:3000',
      modules: {
        '/auth': modules.polarisLogin
      }
    }
  ],
  redirects: {
    '/': '/auth/login'
  },
  appWrapper: AppWrapper,
  featureConfig: {
    authEndpoints: {
      enterCode: process.env.ENTER_CODE,
      authCodeDetails: process.env.AUTH_CODE_DETAILS,
      securityQuestions: process.env.SECURITY_QUESTIONS,
      requestAuthCode: process.env.REQUEST_AUTH_CODE,
      login: process.env.LOGIN,
      mainAppUrl: process.env.MAIN_APP_URL
    },
    chrome: {
      loadingTimeout: process.env.LOADING_TIMEOUT,
      meta: {
        title: 'imarket',
        icon: 'data:;base64,iVBORw0KGgo='
      },
      header: {
        extensions: {
          icons: [],
          extraHeaders: []
        },
        homeLink: process.env.MAIN_APP_URL,
        fixedLinks: [
          { href: `${process.env.MAIN_APP_URL}/home/welcome`, label: 'Welcome' },
          { href: `${process.env.MAIN_APP_URL}/register/register-intro`, label: 'Register' },
          { href: process.env.GENERAL_LINKS_IMARKET, label: 'About imarket', target: '_blank' }
        ]
      },
      footer: {
        copyright: `Polaris U.K LTD ${new Date().getFullYear()}. All rights reserved.`,
        sectionLeft: [
          {
            title: 'section-left',
            items: [
              {
                title: 'copyright'
              },
              {
                title: 'termsandco'
              }
            ]
          }
        ],
        sectionRight: [
          {
            title: 'section-right',
            items: [
              {
                title: 'Terms & Conditions',
                url: process.env.FOOTER_LINKS_TERMS
              },
              {
                title: 'Privacy Statement',
                url: process.env.FOOTER_LINKS_PRIVACY
              },
              {
                title: 'Copyright',
                url: process.env.FOOTER_LINKS_COPYRIGHT
              },
              {
                title: 'Contact Us',
                url: process.env.FOOTER_LINKS_CONTACT
              },
              {
                title: 'Help',
                url: process.env.FOOTER_LINKS_HELP
              }
            ]
          }
        ]
      }
    },
    insuranceAccount: {
      contactNumber: process.env.CONTACT_NUMBER1,
      contactNumber2: process.env.CONTACT_NUMBER2
    },
    registration: {
      linkContacts: process.env.GENERAL_LINKS_CONTACTS,
      linkTerms: process.env.GENERAL_LINKS_TERMS,
      linkWelcomePdf: process.env.GENERAL_LINKS_WELCOME_PDF,
      email: process.env.EMAIL,
      phone1: process.env.CONTACT_NUMBER1,
      phone2: process.env.CONTACT_NUMBER2
    }
  },
  client: () => client
} as ApplicationConfig;
